const en_US = {
    "cluster": "Cluster",
    'reset': 'Reset',
    'refresh': 'Refresh',
    'batch-delete': 'Batch Delete',
    'import-cluster': 'Import Cluster',
    'edit': 'Edit',
    'delete': 'Delete',
    'total-items': 'Total {total} items',
    'index': 'Index',
    'name': 'Name',
    'servers': 'Broker Servers',
    'topic': 'Topic',
    'topic-detail': 'Topic Detail',
    'broker': 'Broker',
    'brokers': 'Brokers',
    'consumer-group': 'Consumer Group',
    'consumer-groups': 'Consumer Groups',
    'consumer-group-detail': 'Consumer Group Detail',
    'created': 'Created',
    'operate': 'Operation',
    'delete-confirm': 'Do you confirm that you want to delete this?',
    'okText': 'OK',
    'cancelText': 'Cancel',
    'save': 'Save',

    'partitions': 'Partitions',
    'replicas': 'Replicas',
    'log-size': 'Log Size',
    'average-log-size': 'Average Log Size',
    'numPartitions': 'Partitions Num',
    'replicationFactor': 'Replication Factor',
    'topic-alter': 'Alter',
    'create-topic': 'Create Topic',

    'topic-management': 'Topic Management',
    'broker-management': 'Broker Management',
    'consumer-group-management': 'Consumer Group Management',

    'topic-count': 'Topic Count',
    'topic-list': 'Topic List',
    'topic-config': 'Configurations',
    'subscribed-topic': 'Subscribed Topics',

    'consume-detail': 'Consume Detail',
    'reset-warning': 'Warning',
    'reset-warning-description': 'Turn off the consumer client before reset.',
    'reset-partition': 'Reset Partition',
    'latest': 'Latest',
    'earliest': 'Earliest',
    'customization': 'Customization',
    'consume-message': 'Consume Message',
    'consume-message-live': 'Live Consume Message',
    'produce-message': 'Produce Message',
    'pull': 'Pull',
    'stop': 'Stop',
    'newest': 'Newest',
    'delay-message': 'Delay Message',

    'security-protocol': 'Security Protocol',
    'sasl-mechanism': 'SASL Mechanism',
    'username': 'Username',
    'password': 'Password',
    'change-password': 'Change Password',
    'logout': 'Logout',
    'delay-message-information1': 'Note: When enabled, 19 built-in topics are automatically created.',
    'delay-message-information2': 'The delay message service based on Kafka supports 18 levels: 1s 5s 10s 30s 1M 2m 3m 4m 5m 6m 7m 8m 9m 10m 20m 30m 1H 2h.',
    'delay-message-information3': 'Send message to topic: delay-message, level: 0-17.',
}

export default en_US;